<template>
<div>
  <div class="full-width cart">
    <div class="container column">
      <h1 class="neue title-mobile">Il tuo carrello</h1>

      <center v-if="!cart.length">
        <h3>Il tuo carrello non contiene ancora alcun prodotto</h3>
        <br><br>
        <router-link class="button" to="/">Scopri lo shop</router-link>
        <br><br>
      </center>

      <div class="full-width spacer" v-if="!cart.length"></div>

      <table class="cart-table no-mobi-tbl" v-if="cart.length">
        <thead>
          <tr>
            <th>Prodotto</th>
            <th class="cart-enlarge"></th>
            <th>Prezzo unitario</th>
            <th>Quantit&agrave;</th>
            <th>Sub totale</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, k) in cart" v-bind:key="k">
            <td class="cart-image">
              <img :src="'https://shop.altromercato.it/products/' + (item.item.masterCode ? item.item.masterCode + '_1.jpg' : item.item.images[0])" />
            </td>
            <td>
               <a :href="'/product/' + item.item.itemNumber + '/' + $utils.getSlug(item.item.productName)" target="_blank">
                  <span class="cart-name">{{$utils.isGift(item.item.itemNumber) ? item.item.productName.replace(/\s+\d+\s*€?$/, '') : item.item.productName}}<span v-if="item.item.masterCode"> - {{item.item.measure}}</span></span>
                </a>
            </td>
            <td>
              <span class="cart-discount" v-if="item.item.sconto > 0 && !($store.state.user.data.has_sconto_dipendenti && $utils.hasExtraScontoDipendenti(item))">-{{item.item.sconto}}%</span>
              <span class="cart-discount" v-if="$store.state.user.data.has_sconto_dipendenti && ($utils.hasExtraScontoDipendenti(item) || !item.item.sconto) && item.item.scontoDipendenti">-{{item.item.scontoDipendenti}}%</span>
              <span class="cart-name">&euro; {{getPrintablePrice($utils.getProductPrice(item.item, false, $store.state.user.data.has_sconto_dipendenti))}}</span>
            </td>
            <td>
              <div class="prod-qty">
                <div :class="'qty-choose' + ' ' + ($utils.isGift(item.item.itemNumber) ? 'gift' : '')" @click="toggleChooseQty(k)">
                  <span class="number">{{item.qty}}</span>
                  <span class="fa fa-chevron-down"></span>

                  <div :class="'choose-tent ' + (item.tent ? 'open' : '')">
                    <div @click="setQty(k, itm)" v-for="(itm, k2) in $utils.getMultipleArray(item.item)" v-bind:key="k2">{{itm}}</div>
                    <div class="nomarg" @click="stopPropoli">
                      <input type="number" @keyup.enter="setQtyCustom(k, $event)" placeholder="Altro" class="pdpdpd" />
                    </div>
                  </div>
                </div>
              </div>
              <span class="fa fa-trash" @click="deleteFromCart(k)"></span>
            </td>
            <td>
              <span class="cart-name">&euro; {{getPrintablePrice($utils.getProductPrice(item.item, false, $store.state.user.data.has_sconto_dipendenti) * item.qty)}}</span>
            </td>
          </tr>
          <tr v-if="$store.state.donation">
            <td class="cart-image">
              <img src="@/assets/donation.jpg" />
            </td>
            <td>
              <span class="cart-name">Donazione</span>
            </td>
            <td>
              <span class="cart-name">&euro; {{getPrintablePrice(1)}}</span>
            </td>
            <td>
              <div class="prod-qty">
                <div :class="'qty-choose'" @click="toggleChooseDonationQty()">
                  <span class="number">{{$store.state.donation}}</span>
                  <span class="fa fa-chevron-down"></span>
                  <div :class="'choose-tent ' + (donationTent ? 'open' : '')">
                    <div @click="$store.commit('setDonation', itm)" v-for="(itm, k2) in $utils.getMultipleArray({stock:99})" v-bind:key="k2">{{itm}}</div>
                    <div class="nomarg" @click="stopPropoli">
                      <input type="number" @keyup.enter="setQtyDonationCustom($event)" placeholder="Altro" class="pdpdpd" />
                    </div>
                  </div>
                </div>
              </div>
              <span class="fa fa-trash" @click="$store.commit('setDonation', 0)"></span>
            </td>
            <td>
              <span class="cart-name">&euro; {{getPrintablePrice(1 * $store.state.donation)}}</span>
            </td>
          </tr>
        </tbody>
      </table>


      <div class="cart-list only-mobi" v-if="cart.length">
        <div v-for="(item, k) in cart" v-bind:key="k" class="cart-line">
          <div class="cart-img">
            <img :src="'https://shop.altromercato.it/products/' + item.item.itemNumber + '_1.jpg'" />
          </div>
          <div class="cart-data">
            <span class="cart-name">{{item.item.productName}}</span>
            <!--<span class="cart-discount">-20%</span>-->
            <!--<span class="cart-name">&euro; {{getPrintablePrice(item.item.productPrice)}}</span>-->

            <div class="cart-qty">
              <div class="prod-qty">
                <div class="qty-choose" @click="toggleChooseQty(k)">
                  <span class="number">{{item.qty}}</span>
                  <span class="fa fa-chevron-down"></span>

                  <div :class="'choose-tent ' + (item.tent ? 'open' : '')">
                    <div @click="setQty(k, itm)" v-for="(itm, k2) in $utils.getMultipleArray(item.item)" v-bind:key="k2">{{itm}}</div>
                  </div>
                </div>
              </div>
              <span class="cart-name mobi-spaced">&euro; {{getPrintablePrice($utils.getProductPrice(item.item, false, $store.state.user.data.has_sconto_dipendenti) * item.qty)}}</span>
              <span class="fa fa-trash" @click="deleteFromCart(k)"></span>
            </div>
          </div>
        </div>
        <div v-if="$store.state.donation" class="cart-line">
          <div class="cart-img">
          </div>
          <div class="cart-data">
            <span class="cart-name">Donazione</span>
            <div class="cart-qty">
              <div class="prod-qty">
                <div :class="'qty-choose'" @click="toggleChooseDonationQty()">
                  <span class="number">{{$store.state.donation}}</span>
                  <span class="fa fa-chevron-down"></span>
                  <div :class="'choose-tent ' + (donationTent ? 'open' : '')">
                    <div @click="$store.commit('setDonation', itm)" v-for="(itm, k2) in $utils.getMultipleArray({stock:99})" v-bind:key="k2">{{itm}}</div>
                  </div>
                </div>
              </div>
              <span class="cart-name mobi-spaced">&euro; {{getPrintablePrice(1 * $store.state.donation)}}</span>
              <span class="fa fa-trash" @click="$store.commit('setDonation', 0)"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="cart.length" :style="{background:$store.state.donation?'#2b9a2f':'#203769',color:'#fff',maxWidth:'100%',width:'900px',margin:'10px auto',boxSizing:'border-box', display: 'flex',fontSize:'1.1em'}">
    <img src="@/assets/immagine_guatemala.jpg" class="donationCover" />
    <div style="padding:20px;line-height:1.5">
      <div style="margin-bottom:10px" v-if="$store.state.donation" @click="$store.commit('setDonation', 0)">
        <div class="centsToggleOn"></div>
        <b style="cursor:pointer">Dona {{$store.state.donation || 1}} €</b>
      </div>
      <div style="margin-bottom:10px" v-if="!$store.state.donation" @click="$store.commit('setDonation', 1)">
        <div class="centsToggleOff"></div>
        <b style="cursor:pointer">Dona {{$store.state.donation || 1}} €</b>
      </div>
      Sostieni i nostri produttori in Guatemala: aggiungi una donazione a favore di <b>Fondazione Altromercato</b>.
      <div style="display:flex;justify-content:space-between;margin-top:30px">
        <a href="https://fondazionealtromercato.it/progetti/agricoltura-sostenibile-guatemala/" target="_blank" style="color:inherit;text-decoration:underline">Scopri di più</a>
        <a href="https://www.centsdonations.com/" target="_blank" style="color:inherit;font-weight:normal">offerto da &nbsp;&nbsp;<svg viewBox="0 0 104.8 20.7" fill="#fff" witdth="52" height="12"><path d="M66.4 5.8h5.5v14.5h6.2V5.8h5.6V.6H66.4v5.2zM10.8.1C4.1.1 0 4.1 0 10.4c0 6.3 4.1 10.3 10.8 10.3 6.4 0 10.8-3.4 10.8-8.5v-.6h-5.9v.4c0 2.8-1.8 3.4-4.9 3.4-3.5 0-4.9-1.3-4.9-4.9 0-3.6 1.5-5 4.9-5 3.1 0 4.9.6 4.9 3.4v.4h5.9v-.7c0-5.1-4.4-8.5-10.8-8.5zM33.3.1c-6.8 0-11 4-11 10.3 0 7 3.9 10.3 11.1 10.3 6.3 0 10.1-3 10.1-7.3v-.2h-5.9v.1c0 1.7-1.1 2.2-4.4 2.2-4 0-5.1-1-5.2-3.7h15.6c.1-.6.2-1.4.2-2.1C43.6 3.5 39.6.1 33.3.1zm-5.5 8.1c.3-2.3 1.8-3.3 5.2-3.3 3.1 0 4.7.9 4.8 3.3h-10zM97.6 8.5 93.4 8c-2.5-.3-3.2-.5-3.2-1.6 0-1 .6-1.5 3.5-1.5 4.1 0 5 .4 5 2.2v.5h5.9c0-5-3.6-7.5-10.4-7.5-6.7 0-9.8 2.9-9.8 6.4s2.1 5.2 6 5.7l5.7.6c2.3.2 3 .6 3 1.5s-.5 1.5-3.9 1.5c-3.8 0-5-.3-5-2.1v-.6h-5.9v.1c0 5 3.5 7.5 10.5 7.5s10-2.6 10-6.3c0-3.4-1.8-5.3-7.2-5.9zM55 0c-8.2 0-10.7 3.4-10.7 7.7v12.5h5.9v-11c0-2.9 1.6-4 4.8-4 3.1 0 4.8 1.1 4.8 4v11.1h5.9V7.7C65.7 3.4 63.6 0 55 0z"/></svg></a>
      </div>
    </div>
  </div>

  <!-- <div class="full-width neue" v-if="shopperOmaggio">
    <div class="bannerino" style="position: relative">
      <h3 style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: #fff; margin: 0; font-size: 32px; width: 65%; text-align: center;">Per te {{shopperOmaggio}} shopper Love People Love Planet in omaggio</h3>
      <img src="@/assets/bannerino_Manifesto22_carrello.jpg" />
    </div>
  </div>

  <div class="full-width neue" v-if="mascaoOmaggio">
    <div class="bannerino" style="position: relative">
      <h3 style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); color: #fff; margin: 0; font-size: 30px; width: 65%; text-align: center;">Per te {{mascaoOmaggio}} Cioccolato Mascao con mandorle e sale in omaggio</h3>
      <img src="@/assets/promo_mascao.jpg" />
    </div>
  </div> -->

  <div class="full-width neue" v-if="cart.length">
    <div class="container p-t-25 p-b-25">
      <div class="col-6 no-mobi">
        <p class="interested-in">Potresti essere interessato anche a:</p>
        <div class="row">
          <ProductThumb column="col-6" :border="true" :productId="item.itemNumber" v-for="item in relatedItems" v-bind:key="item.itemNumber"/>
          <!--<ProductThumb column="col-6" :border="true" productId="00002667" />-->
        </div>
      </div>
      <div class="col-6 totals">
        <p style="line-height: 160%;" v-show="messaggioSpedizioneA">A causa dell'elevato numero di ordini di questa settimana, le consegne tramite corriere potrebbero subire dei lievi ritardi.</p>
        <p style="line-height: 160%;" v-show="messaggioSpedizioneB">La consegna entro Natale è garantita per ordini ricevuti entro il 12/12.<br>Fino al 6 gennaio, i tempi di consegna potrebbero subire dei lievi ritardi.</p>
        <hr class="m-t-77 no-mobi">
        <div class="row subtotal-row">
          <div class="col-6 align-center">
            Sub totale
          </div>
          <div class="col-6 align-center">
            &euro; {{getPrintablePrice(subTotal)}}
          </div>
        </div>
        <div class="row subtotal-row" v-if="$store.state.discount" style="height:auto">
          <div class="col-6 align-center">
            <span style="white-space:nowrap">Codice <b>{{$store.state.discount.code}}</b></span>
            <div v-if="cart.filter(item => $utils.isGift(item.item.itemNumber)).length && !onlyGift" style="line-height:1;font-size:.8em;">Il codice sconto viene applicato a tutti i prodotti del carrello, ad eccezione del prodotto Gift Card.</div>
            <div v-if="onlyGift" style="line-height:1;font-size:.8em;">Non è possibile applicare il codice sconto per l'acquisto di Gift Card.</div>
            <div v-if="$store.state.discount.code.match(/^GC-[A-Z]{1,6}-[A-Z0-9]{10}$/) && ($store.state.discount.single == 1) && parseFloat($store.state.discount.value) > parseFloat(scontoBuono)" style="line-height:1;font-size:.8em;color:#a00">Ti ricordiamo che il valore della Gift Card applicata può essere utilizzato in un solo ordine.<br>Non è previsto un credito residuo.</div>
            <div>
              <a href="#" @click="removeCoupon" class="button button-gray" style="padding-left: 15px;padding-right:15px">Rimuovi coupon</a>
            </div>
          </div>
          <div class="col-6 align-center" v-if="scontoBuono">
            - &euro; {{getPrintablePrice(scontoBuono)}}
          </div>
          <div class="col-6 align-center" v-if="!scontoBuono">
            &euro; {{getPrintablePrice(scontoBuono)}}
          </div>
        </div>
        <div class="row subtotal-row" v-if="hasPromo">
          <div class="col-6 align-center">
            Promozioni
          </div>
          <div class="col-6 align-center">
            - &euro; {{getPrintablePrice(promoDiscount)}}
          </div>
        </div>
        <div class="row subtotal-row" v-if="$store.state.donation">
          <div class="col-6 align-center">
            Donazione
          </div>
          <div class="col-6 align-center">
            &euro; {{getPrintablePrice($store.state.donation ? $store.state.donation : 0)}}
          </div>
        </div>
        <div class="row subtotal-row">
          <div class="col-6 align-center">
            Spedizione
          </div>
          <div class="col-6 align-center">
            &euro; {{getPrintablePrice(shipping)}}
          </div>
        </div>
        <hr>
        <div class="row subtotal-row">
          <div class="col-6 align-center">
            <b>TOTALE</b>
          </div>
          <div class="col-6 align-center">
            &euro; {{getPrintablePrice(total + ($store.state.donation ? $store.state.donation : 0))}}
          </div>
        </div>
        <div class="align-right m-t-10 mobi-center">
          <!--<p>Gli ordini ricevuti dopo le ore 12.00 del 6 agosto saranno affidati al corriere il 16 agosto</p>-->

          <p class="coupon-description" v-if="cart.filter(item => !$utils.isGift(item.item.itemNumber)).length && parseFloat(freeShipping) >= parseFloat(subTotal)">
            <span v-if="!(this.$store.state.discount && (this.$store.state.discount.freeshipping == 1))">Aggiungi ancora {{getPrintablePrice(parseFloat(freeShipping) - parseFloat(subTotal))}} &euro; per la <b>consegna gratuita</b> (da {{getPrintablePrice(parseFloat(freeShipping))}} &euro;)</span>
            <span v-if="this.$store.state.discount && (this.$store.state.discount.freeshipping == 1) && $store.state.discount.code.match(/^GC-/)">La tua gift card azzera anche i costi di spedizione!</span>
          </p>
          <router-link to="/checkout" class="button button-fondo">Procedi al checkout</router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="full-width gray-bg" v-if="cart.length">
    <div class="container p-t-25 p-b-25">
      <div class="col-6 mobi-center mobi-mb25">
        <router-link to="/" class="button button-gray">Continua i tuoi acquisti</router-link>
      </div>
      <div class="col-4 align-right mobi-center mobi-mb10">
        <input type="text" class="coupon-code" placeholder="Codice sconto o Gift Card" v-model="currentCode" v-if="!$store.state.user.data.has_sconto_dipendenti" />
        <input type="text" class="coupon-code" placeholder="Gift Card" v-model="currentCode" v-if="$store.state.user.data.has_sconto_dipendenti" />
      </div>
      <div class="col-2 align-right mobi-center">
        <a href="#" @click="codiceSconto" class="button button-gray">Applica</a>
      </div>
    </div>
  </div>

  <div class="full-width spacer"></div>
</div>
</template>

<script>
import ProductThumb from '@/components/ProductThumb'
import axios from 'axios'
import moment from 'moment';

export default {
  name: 'Cart',

  data: function () {
    return {
      settings: {
        shippingCost: 7.5
      },
      coupons: [],
      subTotal: 0,
      subTotalNoPromo: 0,
      onlyGift: false,
      subTotalAlimentari: 0,
      total: 0,
      donationTent: 0,
      sconto: 0,
      scontoBuono: 0,
      currentCode: '',
      relatedItems: [],
      categoryFreeshipping: [],
      messaggioSpedizioneA: moment().isBetween("2024-11-25", "2024-12-02"),
      messaggioSpedizioneB: moment().isBetween("2024-12-02", "2025-01-07")
    }
  },

  metaInfo() {
    this.$utils.adabraPageView(this, { pageType: 104, pageTitle: 'Carrello' });
    return {
      title: 'Carrello'
    }
  },

  computed: {
    freeShipping () {
      if(this.$store.state.discount && (parseFloat(this.$store.state.discount.sogliaFreeShipping))) {
        return parseFloat(this.$store.state.discount.sogliaFreeShipping);
      }
      return 59;
    },
    shipping: function () {
      if(!this.cart.filter(item => !this.$utils.isGift(item.item.itemNumber)).length) {
        return 0;
      }
      if(this.cart.find(item => item.item.categories.find(cat => this.categoryFreeshipping.includes(cat.categoryCode)))) {
        return 0;
      }
      if(this.$store.state.discount && (this.$store.state.discount.freeshipping == 1)) {
        if(!this.$store.state.discount.category) {
          return 0;
        }
        if(this.cart.find(item => item.item.categories.find(cat => cat.categoryCode === this.$store.state.discount.category))) {
          return 0;
        }
      }
      return this.freeShipping > parseFloat(this.subTotal) ? this.settings.shippingCost : 0;
    },
    cart () {
      return this.$store.state.cart.map((item) => {
        return {
          ...item,
          tent: false,
          item: {
            ...item.item,
            scontoDipendenti: this.$utils.getScontoDipendenti(item)
          }
        }
      })
    },
    hasPromo () {
      return !this.$store.state.user.data.has_sconto_dipendenti && this.$utils.hasPromo(this.cart)
    },
    promoDiscount () {
      return this.$utils.getPromoDiscount(this.cart, this.$utils.getTotalInPromo(this.cart))
    },
    // shopperOmaggio () {
    //   if(moment().isBetween("2022-10-05", "2022-10-24")) {
    //     const numCaffe = this.cart.filter(item => item.item.itemNumber === '00001260').shift()?.qty || 0;
    //     const numCioccolato = this.cart.filter(item => item.item.itemNumber === '00003446').shift()?.qty || 0;
    //     const numSalsa = this.cart.filter(item => item.item.itemNumber === '00003803').shift()?.qty || 0;
    //     return Math.floor(Math.min(numCaffe / 2, numCioccolato / 4, numSalsa / 2))
    //   }
    //   return false;
    // },
    // mascaoOmaggio () {
    //   if(moment().isBetween("2022-10-26", "2022-11-14")) {
    //     let numMascao = 0;
    //     this.cart.forEach(item => {
    //       console.log(item)
    //       if(item.item.categories.filter((cat) => cat.categoryCode == 'EF00006').length) {
    //         numMascao += parseInt(item.qty);
    //       }
    //     })
    //     return Math.floor(numMascao / 3)
    //   }
    //   return false;
    // },
  },

  watch: {
    cart () {
      this.calcTotals()
    }
  },

  mounted () {
    this.$utils.checkCart(this)
    let codesUrl = 'https://shop.altromercato.it/cms/codes_2.php'
    if(this.$config.backendUrl === 'https://shop.altromercato.it/staging/') {
      codesUrl = 'https://shop.altromercato.it/cms_staging/codes_2.php'
    }
    axios.get(codesUrl, {params: {email: this.$store.state.user ? this.$store.state.user.data.email : ''}})
      .then((res) => {
        this.coupons = res.data.map(coupon => {
          if(!coupon.percent && !coupon.value) {
            coupon.value = 0;
          }
          return coupon;
        })
      })
      .catch((err) => console.log(err))
    axios.get('https://shop.altromercato.it/cms/2/category_freeshipping')
      .then((res) => {
        this.categoryFreeshipping = res.data;
        this.calcTotals()
      })
      .catch((err) => console.log(err))
  },

  methods: {

    deleteFromCart (id) {
      this.$store.commit('deleteFromCart', id)
      this.calcTotals()
    },

    checkGifts () {
      let onlyGift = true;
      this.cart.forEach((item) => {
        if(!this.$utils.isGift(item.item.itemNumber)) {
          onlyGift = false;
        }
      })
      this.onlyGift = onlyGift;
    },

    calcTotals () {
      this.checkGifts();
      this.subTotal = 0
      this.subTotalAlimentari = 0
      this.subTotalNoPromo = 0
      this.total = 0
      this.sconto = 0
      this.scontoBuono = 0

      let cats = []
      let inCart = []


      this.cart.forEach((item) => {
        cats.push(item.item.productCategory)
        inCart.push(item.item.itemNumber)

        if (!this.$utils.hasDiscount(item.item)) {
          this.subTotalNoPromo += this.$utils.getProductPrice(item.item, false, this.$store.state.user.data.has_sconto_dipendenti) * parseFloat(item.qty)
        }

        this.subTotal += this.$utils.getProductPrice(item.item, false, this.$store.state.user.data.has_sconto_dipendenti) * parseFloat(item.qty)

        if (item.item.categories.filter((cat) => cat.categoryCode == 'EC61000').length) {
          this.subTotalAlimentari += parseFloat(item.item.productPrice * item.qty)
        }
      })

      this.relatedItems = this.$utils.getRandomProducts(cats, inCart)

      this.subTotal = this.subTotal.toFixed(2)

      let discountSubtotal = parseFloat(this.$store.state.discount.excludePromo) ? this.subTotalNoPromo : this.subTotal

      if (this.$store.state.discount && parseFloat(this.$store.state.discount.minCosts) > parseFloat(discountSubtotal)) {
        //this.$toast.open('Questo buono è valido per almeno ' + this.$store.state.discount.minCosts + '€ di acquisto')
        this.$store.commit('removeCoupon')
        return false
      }

      if (this.$store.state.discount && (this.$store.state.discount.code.indexOf("GC-") !== 0) && (this.$store.state.discount.code.indexOf("AIB-") !== 0) && (this.$store.state.discount.code.indexOf("AMB-") !== 0) && this.$store.state.user.data.has_sconto_dipendenti) {
        this.$store.commit('removeCoupon')
      }

      if(this.$store.state.discount && this.$store.state.discount.category && !this.cart.find(item => item.item.categories.find(cat => cat.categoryCode === this.$store.state.discount.category))) {
        this.$store.commit('removeCoupon')
      }

      if (this.$store.state.discount) {
        const partialSubTotal = this.cart
          .filter(item => !this.$utils.isGift(item.item.itemNumber))
          .reduce((tot, item) => tot + this.$utils.getProductPrice(item.item, false, this.$store.state.user.data.has_sconto_dipendenti) * parseFloat(item.qty), 0)
        if (this.$store.state.discount.percent) {
          this.sconto = ( partialSubTotal / 100 ) * this.$store.state.discount.percent
          this.scontoBuono = ( partialSubTotal / 100 ) * this.$store.state.discount.percent
        } else {
          const scontoBuono = Math.min(parseFloat(partialSubTotal), parseFloat(this.$store.state.discount.value))
          this.sconto = parseFloat(scontoBuono)
          this.scontoBuono = scontoBuono
        }
      }

      if (this.hasPromo) {
        this.sconto += this.$utils.getPromoDiscount(this.cart, this.$utils.getTotalInPromo(this.cart))
      }

      this.total = (parseFloat(this.subTotal) + this.shipping).toFixed(2) - parseFloat(this.sconto).toFixed(2)
      this.$forceUpdate()
    },

		toggleChooseDonationQty () {
      if (!this.donationTent) {

        document.querySelector('body').removeEventListener('click', this.tendina)

        setTimeout(() => {
          document.querySelector('body').addEventListener('click', this.tendina, { once: true })
        }, 200)

        this.donationTent = true
      } else {
        this.donationTent = false
      }

      this.$forceUpdate()

    },

    toggleChooseQty (id) {

      if (!this.cart[id].tent) {

        document.querySelector('body').removeEventListener('click', this.tendina)

        setTimeout(() => {
          document.querySelector('body').addEventListener('click', this.tendina, { once: true })
        }, 200)

        this.cart[id].tent = true
      } else {
        this.cart[id].tent = false
      }

      this.$forceUpdate()
    },

    stopPropoli (evt) {
      evt.stopPropagation()
    },

    tendina () {
      this.cart.forEach((item, k) => {
        this.cart[k].tent = false
      })

      this.donationTent = false

      this.$forceUpdate()
    },

    setQtyCustom (id, evt) {
      let qty = parseInt(evt.target.value)
      if(isNaN(qty) || !isFinite(qty) || (qty < 1)) {
        qty = 1
      }
      if (this.cart[id].item.stock < qty) {
        qty = this.cart[id].item.stock
      }

      let multiplo = parseInt(this.cart[id].item.multiplo)

      if (multiplo > 1 && (qty % multiplo != 0)) {
        qty = qty - (qty % multiplo) + multiplo
      }

      this.cart[id].qty = qty
      this.$store.commit('resetCart', this.cart)
      this.calcTotals()
      this.$forceUpdate()
      evt.target.value = ''

      setTimeout(() => {
        this.cart[id].tent = false
        this.$forceUpdate()
      }, 200)
    },

    setQtyDonationCustom(evt) {
      let qty = parseInt(evt.target.value)
      if(isNaN(qty) || !isFinite(qty) || (qty < 1)) {
        qty = 1
      }

      this.$store.commit('setDonation', qty)
      evt.target.value = ''

      setTimeout(() => {
        this.donationTent = false
        this.$forceUpdate()
      }, 200)
    },

    setQty (id, qty) {
      this.cart[id].qty = qty
      this.$store.commit('resetCart', this.cart)
      this.calcTotals()
      this.$forceUpdate()
      setTimeout(() => {
        this.cart[id].tent = false
        this.$forceUpdate()
      }, 200)
    },

    getPrintablePrice (price) {
      return ((parseFloat(price)).toFixed(2) + "").replace('.', ',')
    },

    removeCoupon () {
      this.$store.commit('removeCoupon')
      this.calcTotals()
    },

    async codiceSconto (evt) {
      evt.preventDefault()

      if((this.currentCode.toLowerCase().indexOf("gc-") !== 0) && (this.currentCode.toLowerCase().indexOf("aib-") !== 0) && (this.currentCode.toLowerCase().indexOf("amb-") !== 0) && this.$store.state.user.data.has_sconto_dipendenti) {
        this.currentCode = ''
        this.$toast.open('Codice sconto non utilizzabile con questo account')
        return
      }

      let found = false
      this.coupons.forEach((item) => {
        if (item.code.toLowerCase() == this.currentCode.toLowerCase()) {
          this.$store.commit('setCoupon', item)
          found = true
        }
      })

      if (found) {
        let discountSubtotal = this.$store.state.discount.excludePromo ? this.subTotalNoPromo : this.subTotal

        if (parseFloat(this.$store.state.discount.minCosts) > parseFloat(discountSubtotal)) {
          this.$toast.open('Questo buono è valido per almeno ' + this.$store.state.discount.minCosts + '€ di acquisto')
          this.$store.commit('removeCoupon')
          return false
        }

        if(parseInt(this.$store.state.discount.single) || (this.$store.state.discount.single === true)) {
          if(!this.$store.state.user.loggedIn) {
            this.$toast.open('Questo buono è valido solo per gli utenti registrati')
            this.$store.commit('removeCoupon')
            this.currentCode = ''
            return false
          }
          const data = new FormData();
          data.append('coupon', this.$store.state.discount.code)
          data.append('email', this.$store.state.user.data.email.toLowerCase())
          const resp = await axios.post(this.$config.backendUrl + 'index.php?action=get_coupon_usage', data)
          if(resp.data > 0) {
            this.$toast.open('Codice sconto già utilizzato')
            this.$store.commit('removeCoupon')
            this.currentCode = ''
            return false
          }
        }

        if(this.$store.state.discount.start.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
          const start = this.$store.state.discount.start.replace(/-/g, '')
          const now = (new Date()).toISOString().split('T')[0].replace(/-/g, '')
          if(now < start) {
            this.$toast.open('Codice sconto non valido o scaduto')
            this.$store.commit('removeCoupon')
            this.currentCode = ''
            return false
          }
        }

        if(this.$store.state.discount.end.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/)) {
          const end = this.$store.state.discount.end.replace(/-/g, '')
          const now = (new Date()).toISOString().split('T')[0].replace(/-/g, '')
          if(now > end + 1) {
            this.$toast.open('Codice sconto non valido o scaduto')
            this.$store.commit('removeCoupon')
            this.currentCode = ''
            return false
          }
        }

        if(this.$store.state.discount && this.$store.state.discount.category && !this.cart.find(item => item.item.categories.find(cat => cat.categoryCode === this.$store.state.discount.category))) {
          this.$toast.open('Il codice non è valido per i prodotti a carrello')
          this.$store.commit('removeCoupon')
          this.currentCode = ''
          return false

        }

        if (this.$store.state.discount.logged && !this.$store.state.user.loggedIn) {
          this.$toast.open('Devi avere un account per utilizzare questo codice sconto')
          this.$store.commit('removeCoupon')
        } else {

          if (this.$store.state.discount.email && this.$store.state.user.data.email.toLowerCase() != this.$store.state.discount.email.toLowerCase()) {
            this.$toast.open('Coupon non valido')
            this.$store.commit('removeCoupon')
          } else {
            this.$toast.open('Codice sconto applicato correttamente')
            this.currentCode = ''
            this.calcTotals()
          }
        }
      } else {
        this.$toast.open('Codice sconto non valido o scaduto')
      }
    }
  },

  components: {
    ProductThumb
  }
}
</script>

<style scoped>
.cart h1 {
  font-weight: 600;
  font-size: 38px;
  margin: 40px 0;
  width: 100%;
}

.cart-table {
  width: 100%;
  border-style:none none solid;
  border-collapse: collapse;
  margin-bottom: 46px;
  border: 0px solid black;
}

.cart .container.column {
  flex-direction: column;
}

thead tr {
  border-bottom: 1px solid #cfcfcf;
  height: 40px;
  line-height: 40px;
}

thead th {
  text-align: left;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
}

tbody tr {
  height: 120px;
  border-bottom: 1px solid #cfcfcf;
}

.cart-image img {
  width: 90px;
  height: 90px;
  border: 1px solid #eee;
}

.cart-name {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.3px;
}

.cart-discount {
  display: block;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 12px;
  font-weight: 500;
  width: 35px;
  margin-bottom: 3px;
}

.prod-qty {
  display: inline-block;
  margin-right: 15px;
}

.fa-trash {
  cursor: pointer;
}

.coupon-code {
  background-color: #fff;
  text-align: center;
  height: 32px;
  line-height: 32px;
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}

.button {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
}

.coupon-description {
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  letter-spacing: 0.5px;
}

hr {
  border: 1px solid #e3e3e3;
}

.subtotal-row {
  height: 32px;
  line-height: 32px;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.totals {
  padding-left: 40px;
}

.interested-in {
  font-weight: 500;
  font-size: 23px;
  letter-spacing: 0.3px;
}

.pdpdpd {
  width: 100%;
  height: 100%;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.nomarg {
  margin: 0 !important;
  padding: 0 !important;
}

.qty-choose.gift {
  pointer-events: none;
}

.qty-choose.gift .fa {
  display: none;
}

@media (max-width: 680px) {
  .mobi-center {
    text-align: center !important;
  }

  .mobi-mb25 {
    margin-bottom: 25px;
  }

  .mobi-mb10 {
    margin-bottom: 10px;
  }

  .interested-in {
    display: none;
  }

  .totals {
    padding: 0 10px;
  }

  .coupon-code {
    width: 95%;
  }

  .cart-line {
    padding: 0 10px;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
  }

  .cart-line:last-child {
    border: none;
  }

  .cart-img {
    width: 35%;
  }

  .cart-img img {
    width: 100%;
  }

  .cart-data {
    width: 65%;
    margin-top: 30px;
  }

  .cart-qty {
    width: 100% !important;
    margin: 10px 0 30px 0;
  }

  .title-mobile {
    margin-bottom: 0 !important;
    margin-top: 10px !important;
  }
}
.centsToggleOn,
.centsToggleOff {
  display: inline-block;
  background: #fff;
  height: 20px;
  width: 40px;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: -3px;
  position: relative;
  cursor: pointer;
}
.centsToggleOn::before,
.centsToggleOff::before {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #545454;
}
.centsToggleOff::before {
  left: 2px;
  right: auto;
}
.donationCover {
  object-fit: cover;
}
@media (max-width: 550px) {
  .donationCover {
    display: none;
  }
}
</style>
